import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import MainFeature1 from "components/features/TwoColWithButton.js";
import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";
import LandingBg from "images/landing-bg.webp";

import OurTeam from "images/team/our-team.png";
import GetStarted from "../components/cta/GetStarted";
import Hero from "components/hero/BackgroundAsImageWithCenteredContent";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
const HighlightedText = tw.span`text-primary-500`;

export default () => {
  return (
    <AnimationRevealPage>
      <Hero
        roundedHeaderButton={true}
        heading="ABOUT US"
        description="We are experts in Transforming Musculoskeletal Health, offering cutting-edge therapies and personalised care to improve mobility, alleviate pain, and enhance quality of life."
        buttonText="Learn More"
        image={LandingBg}
      />
      <MainFeature1
        subheading={""}
        heading={
          <>
            <HighlightedText>Our</HighlightedText> Vision
          </>
        }
        buttonRounded={false}
        primaryButtonText="Contact Us"
        hideButton
        description="We believe in a world where motion analysis goes beyond the surface, capturing not justvisible movements, but also the intricate interplay of anatomical structures beneath. Ourgoal is to redefine motion analysis, delivering precise, comprehensive, and clinically relevantinsights to enhance patient outcomes in the field of musculoskeletal health."
        imageSrc="https://images.unsplash.com/3/doctype-hi-res.jpg?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80"
        textOnLeft={false}
      />
      <MainFeature1
        subheading={""}
        heading={
          <>
            <HighlightedText>Our</HighlightedText> Team
          </>
        }
        buttonRounded={false}
        hideButton
        description="Our multidisciplinary team brings together a diverse array of experts. Each member plays apivotal role, contributing their unique knowledge and skills to the development of MAI-Motion."
        subdescription="Our orthopaedic surgeons provide a deep understanding of musculoskeletal conditions andthe clinical needs of patients and healthcare professionals. Our computer vision scientistsshape the advanced algorithms and artificial intelligence behind our innovative technology.Our biomechanics academics ensure the rigorous scientific underpinning of our approach,contributing a wealth of research and expertise in the field of human movement."
        imageSrc={OurTeam}
        textOnLeft
      />
      <TeamCardGrid subheading={<Subheading>Our Team</Subheading>} />
      <GetStarted />

      <Footer />
    </AnimationRevealPage>
  );
};
