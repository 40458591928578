import React from "react";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings";
import { SectionDescription } from "components/misc/Typography";
import { ReactComponent as SvgDotPatternIcon } from "images/dot-pattern.svg";
import { PrimaryButton as PrimaryButtonBase } from "../misc/Buttons";

const HeadingContainer = tw.div`text-center`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)``;
const Description = tw(SectionDescription)`mx-auto`;

const Posts = tw.div`mt-12 flex flex-wrap -mr-3 relative`;
const PostAnchor = tw.a`flex flex-col h-full bg-gray-200 rounded`;
const PostDiv = tw.div`flex flex-col h-full bg-gray-200 rounded`;
const PostImage = styled.div`
  ${(props) => css`
    background-image: url("${props.imageSrc}");
  `}
  ${tw`h-64 sm:h-80 bg-center bg-cover rounded-t`}
`;
const PostText = tw.div`flex-1 px-6 py-8`;
const PostTitle = tw.h6`font-bold group-hocus:text-primary-500 transition duration-300 `;
const AuthorInfo = tw.div`flex`;
const AuthorTextInfo = tw.div`text-xs text-gray-600`;
const AuthorName = tw.div`font-semibold mt-2`;

const PostContainer = styled.div`
  ${tw`relative z-20 mt-10 sm:pt-3 pr-3 w-full sm:w-1/2 lg:w-1/3 max-w-sm mx-auto sm:max-w-none sm:mx-0`}
`;

const DecoratorBlob1 = tw(
  SvgDotPatternIcon,
)`absolute bottom-0 left-0 w-32 h-32 mb-3 ml-3 transform -translate-x-1/2 translate-y-1/2 fill-current text-gray-500 opacity-50`;
const DecoratorBlob2 = tw(
  SvgDotPatternIcon,
)`absolute top-0 right-0 w-32 h-32 mt-16 mr-6 transform translate-x-1/2 -translate-y-1/2 fill-current text-gray-500 opacity-50`;
const PrimaryButton = styled(PrimaryButtonBase)(() => [
  tw`mt-8 md:mt-8 text-sm rounded-full`,
]);
export default ({
  subheading = "",
  heading = "We love writing.",
  description = "",
  linkText,
  grids = [],
}) => {
  return (
    <Container>
      <ContentWithPaddingXl>
        <HeadingContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          {heading && <Heading>{heading}</Heading>}
          {description && <Description>{description}</Description>}
        </HeadingContainer>
        <Posts>
          {grids.map((post, index) => (
            <PostContainer key={index}>
              {post.url ? (
                <PostAnchor className="group" href={post.url}>
                  <PostImage imageSrc={post.imageSrc} />
                  <PostText>
                    <PostTitle>{post.title}</PostTitle>
                    <AuthorInfo>
                      <AuthorTextInfo>
                        <AuthorName>{post.authorName}</AuthorName>
                      </AuthorTextInfo>
                    </AuthorInfo>
                  </PostText>
                </PostAnchor>
              ) : (
                <PostDiv className="group">
                  <PostImage imageSrc={post.imageSrc} />
                  <PostText>
                    <PostTitle>{post.title}</PostTitle>
                    <AuthorInfo>
                      <AuthorTextInfo>
                        <AuthorName>{post.authorName}</AuthorName>
                      </AuthorTextInfo>
                    </AuthorInfo>
                  </PostText>
                </PostDiv>
              )}
            </PostContainer>
          ))}
          <DecoratorBlob1 />
          <DecoratorBlob2 />
        </Posts>
        {linkText ? (
          <div style={{ textAlign: "center" }}>
            <PrimaryButton>{linkText}</PrimaryButton>
          </div>
        ) : null}
      </ContentWithPaddingXl>
    </Container>
  );
};
