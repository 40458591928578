import tw from "twin.macro";
import React, {useState} from "react";
import "react-responsive-modal/styles.css";
import Notification from "images/new-notification.apng";
import {Modal} from "react-responsive-modal";
import {Subheading as SubheadingBase} from "./misc/Headings";
import SuccessModal from "./SuccessModal";
import SubscribeForm from "./forms/SubscribeForm";

const Image = tw.img`w-64 mb-8 mx-auto rounded-full`;
const Subheading = tw(
    SubheadingBase,
)`text-xl text-center mb-3 text-white font-normal`;
const Heading = tw.h4`text-4xl font-black tracking-wide text-center text-white`;
const Container = tw.div`text-center`;

const NewsLetterModal = (props) => {
    const [success, setSuccess] = useState(false);

    return !success ? (
        <Modal
            open
            onClose={props.onClose}
            center
            styles={{
                modal: {padding: 40, background: "#1a2650"},
                closeIcon: {fill: "white"},
            }}
        >
            <Container>
                <Image src={Notification} alt="Notification Bell"/>
                <Heading>Subscribe to our Newsletter</Heading>
                <Subheading>
                    We deliver high quality blog posts written by professionals weekly.
                    And we promise no spam.
                </Subheading>
                <SubscribeForm onSuccess={() => setSuccess(true)}/>
            </Container>
        </Modal>
    ): <SuccessModal onClose={props.onClose}/>;
};

export default NewsLetterModal;
