import tw from "twin.macro";
import React from "react";
import "react-responsive-modal/styles.css";
import {Modal} from "react-responsive-modal";
import {Subheading as SubheadingBase} from "./misc/Headings";

const Subheading = tw(
    SubheadingBase,
)`text-xl text-center mb-3 text-white font-normal`;
const Heading = tw.h4`text-4xl font-black tracking-wide text-center text-white`;
const Container = tw.div`text-center`;

const SuccessModal = (props) => {
    return (
        <Modal
            open
            onClose={props.onClose}
            center
            styles={{
                modal: {padding: 40, background: "#1a2650"},
                closeIcon: {fill: "white"},
            }}
        >
            <Container>
                <Heading>🎉 Congrats</Heading>
                <Subheading>
                    Your email was joined in our newsletter list. Thank you so much!
                </Subheading>
            </Container>
        </Modal>
    );
};

export default SuccessModal;
