import React from "react";
import styled from "styled-components"; //eslint-disable-line
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-9.svg";
import { ContentWithPaddingXl, Container } from "components/misc/Layouts";

const PrimaryBackgroundContainer = styled.div`
  ${tw`py-20 lg:py-24 bg-gray-200 rounded-lg relative`}
`;
const Row = tw.div`px-8 mx-auto flex items-center relative z-10 flex-col lg:flex-row text-center`;

const ColumnContainer = tw.div`lg:w-full mx-auto`;
const TextContainer = tw(ColumnContainer)``;
const Text = tw.h5`text-gray-600 text-2xl sm:text-3xl font-bold`;

const HighlightedText = tw.span`text-gray-100`;
const Description = tw.p`mt-4 text-center text-sm md:text-base lg:text-lg font-medium leading-relaxed text-gray-600`;

const DecoratorBlobContainer = tw.div`absolute inset-0 overflow-hidden rounded-lg`;
const DecoratorBlob1 = tw(
  SvgDecoratorBlob1,
)`absolute bottom-0 left-0 w-80 h-80 transform -translate-x-20 translate-y-32 text-primary-100 opacity-50`;
const DecoratorBlob2 = tw(
  SvgDecoratorBlob1,
)`absolute top-0 right-0 w-80 h-80 transform  translate-x-20 -translate-y-64 text-primary-100 opacity-50`;
export default ({ pushDownFooter = true }) => {
  return (
    <Container css={pushDownFooter && tw`mb-20 lg:mb-24`}>
      <ContentWithPaddingXl>
        <PrimaryBackgroundContainer>
          <Row>
            <TextContainer>
              <Text>
                <HighlightedText className="gradient-text">
                  Welcome to the future
                </HighlightedText>{" "}
                of On-Recovery
              </Text>
              <Description>
                Explore our site to learn more about our technology, see it in
                action, or get in touch with us.
              </Description>
            </TextContainer>
          </Row>
          <DecoratorBlobContainer>
            <DecoratorBlob1 />
            <DecoratorBlob2 />
          </DecoratorBlobContainer>
        </PrimaryBackgroundContainer>
      </ContentWithPaddingXl>
    </Container>
  );
};
