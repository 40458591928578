import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import Home from './pages/LandingPage.js';
import AboutUs from './pages/AboutUs';
import ContactUs from './pages/ContactUs';
import GlobalStyles from "./styles/GlobalStyles";

const routes = [
    {path: "/about-us", component: AboutUs, title: "On Recovery - About Us"},
    {path: "/contact-us", component: ContactUs, title: "On Recovery - Contact Us"},
    {path: "/", component: Home, title: "On Recovery - You, Only Better"},
];

const App = () => (
    <>
        <GlobalStyles/>
        <Router>
            <Routes>
                {routes.map(({path, component: Component, title}, index) => (
                    <Route key={index} exact path={path} element={
                        <>
                            <Helmet>
                                <title>{title}</title>
                                <meta name="description" content={title}/>
                                <meta property="og:title" content={title}/>
                                <meta property="og:description" content={title}/>
                                <meta name="twitter:title" content={title}/>
                                <meta name="twitter:description" content={title}/>
                            </Helmet>
                            <Component/>
                        </>
                    }/>
                ))}
            </Routes>
        </Router>
    </>
);

export default App;
