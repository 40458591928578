import tw from "twin.macro";
import React, {useState} from "react";
import "react-responsive-modal/styles.css";
import {PrimaryButton as PrimaryButtonBase} from "../misc/Buttons";
import SuccessModal from "../SuccessModal";
import axios from "axios";

const Form = tw.form`mt-8 lg:mt-6 text-sm sm:flex max-w-xs sm:max-w-none mx-auto sm:mx-0`;
const Input = tw.input`bg-white px-6 py-3 font-medium rounded sm:rounded-r-none border-2 sm:border-r-0 border-gray-400 hover:border-primary-500 focus:outline-none transition duration-300 w-full`;
const SubscribeButton = tw(
    PrimaryButtonBase,
)`mt-4 sm:mt-0 w-full sm:w-auto rounded sm:rounded-l-none px-8 py-3 bg-primary-700`;


const SubscribeForm = (props) => {
    const [success, setSuccess] = useState(false);
    const [formValues, setFormValues] = useState({
        email: "",
    });

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormValues({
            ...formValues,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // API call to submit the form data
        axios.post(`${process.env.REACT_APP_HTTP_DOMAIN}/subscribe`, formValues, {
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => {
                if (props.onSuccess) {
                    props.onSuccess();
                } else {
                    setSuccess(response.data.message);
                }
                setFormValues({
                    email: "",
                });
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };


    return !success ? (

        <Form onSubmit={handleSubmit} method="get" action="#">
            <Input
                type="email"
                name="email"
                placeholder="Your Email Address"
                value={formValues.email}
                onChange={handleChange}
            />
            <SubscribeButton type="submit">Subscribe</SubscribeButton>
        </Form>
    ): <SuccessModal onClose={()=> {
        setSuccess(false);
        if(props.onClose){
            props.onClose()
        }
    }}/>;
};

export default SubscribeForm;
