import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import ContactUsForm from "components/forms/TwoColContactUsWithIllustrationFullForm.js";
import Features from "../components/features/ThreeColWithSideImage";
import GetStarted from "../components/cta/GetStarted";
import Hero from "../components/hero/BackgroundAsImageWithCenteredContent";
import LandingBg from "images/landing-bg.webp";

import CPUIcon from "images/cpu.svg";
import LayerIcon from "images/layers.svg";
import WindIcon from "images/wind.svg";
const Subheading = tw.span`uppercase tracking-widest font-bold text-gray-100`;

export default () => {
  return (
    <AnimationRevealPage>
      <Hero
        roundedHeaderButton={true}
        image={LandingBg}
        subheading={<Subheading>Support</Subheading>}
        heading={<>Contact Us</>}
        description="If you require assistance or have any technical questions regarding MAI-Motion,our customer support team is ready to assist you. Please email us at move@mai-motion.com"
      />

      <Features
        heading=""
        subheading=""
        description=""
        cards={[
          {
            imageSrc: CPUIcon,
            title: "Support",
            description:
              "If you require assistance or have any technical questions regarding MAI-Motion,our customer support team is ready to assist you. Please email us at move@mai-motion.com",
          },
          {
            imageSrc: LayerIcon,
            title: "Media Inquiries",
            description:
              "If you are a member of the media and would like to request information,schedule an interview, or discuss potential collaborations, please reach out to our mediarelations team at move@mai-motion.com",
          },
          {
            imageSrc: WindIcon,
            title: "General Inquiries",
            description:
              "For any general inquiries or if you have a question that does not fall underthe above categories, please send us a message using the contact form below. We will makeevery effort to respond to your inquiry in a timely manner.",
          },
        ]}
      />
      <ContactUsForm />
      <GetStarted />
      <Footer />
    </AnimationRevealPage>
  );
};
