import React, {useState} from "react";
import tw from "twin.macro";

import Hero2Image from "images/hero-image-2.webp";
import LandingBg from "images/landing-bg.webp";

import PhysiotherapyImg from "images/sliders/physiotherapy.webp";
import KneeImg from "images/sliders/knee.webp";
import RoboticsImg from "images/sliders/robotics.webp";
import OsteopathyImg from "images/sliders/osteopathy.webp";
import BloodImg from "images/sliders/blood.webp";
import CryoImg from "images/sliders/cryo.webp";
import SportMassageImg from "images/sliders/sport-massage.webp";
import FootPressureImg from "images/sliders/foot-pressure.webp";
import GaitAnalysisImg from "images/sliders/gait-analysis.webp";
import BracingImg from "images/sliders/bracing.webp";
import WellBeingImg from "images/sliders/wellbeing.webp";

import Service1 from "images/solutions/chritsmas-1.webp";
import Service2 from "images/solutions/chritsmas-2.webp";
import Service3 from "images/solutions/getting-ready.webp";

import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/BackgroundAsImageWithCenteredContent.js";
import Hero2 from "components/hero/BackgroundAsImage.js";
import Features from "components/features/ThreeColSimple.js";
import GridColFeatures from "components/features/ThreeColGrid.js";
import GetStarted from "components/cta/GetStarted";
import Footer from "../components/footers/FiveColumnWithInputForm";
import NewsLetterModal from "../components/NewsLetterModal";
import {Container, ContentWithPaddingXl} from "../components/misc/Layouts";
import {SectionHeading, Subheading as SubheadingBase} from "../components/misc/Headings";
import Carousel from "components/testimonials/TwoColumnWithImageAndRating.js";


import styled from "styled-components";
import {SectionDescription} from "../components/misc/Typography";

const Subheading = tw(
    SubheadingBase,
)`uppercase tracking-widest font-bold`;

const BgContainer = styled.div`
  ${tw`relative  bg-center bg-gray-200`}
`;
const Heading = tw(SectionHeading)``;
const Description = tw(
    SectionDescription,
)`text-center mx-auto max-w-full`;

export default () => {
    const [openModal, setOpenModal] = useState(false);
    return (
        <AnimationRevealPage>
            <Hero image={LandingBg} onClick={() => setOpenModal(true)} roundedHeaderButton={true}/>
            <Container tw="my-16">
                <ContentWithPaddingXl>
                    <Subheading style={{textAlign: "center"}}>
                        <span className="gradient-text">Data from you, designed for you.</span>
                    </Subheading>

                    <Heading>
                        RETHINK PHYSICAL WELLBEING
                    </Heading>
                    <Description>
                        It’s time for an approach that’s anything but routine.
                        <br/>
                        Where the guidance of a group of experts meets the latest motion technology.
                    </Description>
                </ContentWithPaddingXl>
            </Container>

            <Hero2
                bgImage={Hero2Image}
                buttonText=""
                heading="Experienced healthcare professionals and scientists, specialising in muscle and joint performance and rehabilitation."
                description="Focused on human motion to provide personalised solutions through technology and research."
            />

            <Carousel
                subheading=""
                heading={<><span className="gradient-text">Personalised Approach</span> to Physical Health</>}
                testimonials={[
                    {
                        imageSrc: PhysiotherapyImg,
                        heading: "PHYSIOTHERAPY",
                        description: "Data driven personalised musculoskeletal physical therapy for performance, injury prevention and recovery"
                    },
                    {
                        imageSrc: RoboticsImg,
                        heading: "MSK ROBOTIC REHAB",
                        description: "Intelligent robotic devices with specific deep learning algorithms to enhance musculoskeletal rehab with objective data."
                    },
                    {
                        imageSrc: KneeImg,
                        heading: "KNEE KG",
                        description: "Evidence based, Hi-tech motion analysis designed specifically for the knee, to track and tailor your rehab programme"
                    },
                    {
                        imageSrc: OsteopathyImg,
                        heading: "OSTEOPATHY",
                        description: "Neurological reprogramming and manipulation to reset the body to it’s optimal function."
                    }
                ]}
                cards={[
                    {
                        imageSrc: null,
                        title: "Physiotherapy",
                        description:
                            "Data driven personalised musculoskeletal physical therapy for performance, injury prevention and recovery",
                        link: "#",
                        linkText: "More Info"

                    },
                    {
                        imageSrc: null,
                        title: "MSK Robotic Rehab",
                        description: "Intelligent robotic devices with specific deep learning algorithms to enhance musculoskeletal rehab with objective data.  ",
                        link: "#",
                        linkText: "More Info"
                    },
                    {
                        imageSrc: null,
                        title: "Knee KG",
                        description: "Evidence based, Hi-tech motion analysis designed specifically for the knee, to track and tailor your rehab programme",
                        link: "#",
                        linkText: "More Info"
                    },
                    {
                        imageSrc: null,
                        title: "Osteopathy",
                        description: "Neurological reprogramming and manipulation to reset the body to it’s optimal function. ",
                        link: "#",
                        linkText: "More Info"
                    },
                ]}
            />
            <BgContainer>
                <Carousel
                    heading={<><span className="gradient-text">World-Class</span> Excellence in Performance</>}
                    subheading=""
                    testimonials={[

                        {
                            imageSrc: BloodImg,
                            heading: "BLOOD FLOW RESTRICTION",
                            description: "Biologically proven to multiply your effort with minimal strain on your muscles, leveraging the lactic acid to leapfrog recovery and training."
                        },
                        {
                            imageSrc: CryoImg,
                            heading: "CRYO COMPRESSION THERAPY",
                            description: "Reduces inflammation, pain and stiffness, promotes circulation and enhances lymphatic drainage."
                        },
                        {
                            imageSrc: SportMassageImg,
                            heading: "SPORTS MASSAGE",
                            description: "Release muscle tension and scarring caused by repetitive movement and injuries by an accredited specialist."
                        }
                    ]}
                    bgColor="white"
                    cards={[
                        {
                            imageSrc: null,
                            title: "Cryo Compression Therapy",
                            description:
                                "Reduces inflammation, pain and stiffness, promotes circulation and enhances lymphatic drainage.",
                            link: "#",
                            linkText: "More Info",

                        },
                        {
                            imageSrc: null,
                            title: "Blood Flow Restriction",
                            description: "Biologically proven to multiply your effort with minimal strain on your muscles, leveraging the lactic acid to leapfrog recovery and training.",
                            link: "#",
                            linkText: "More Info"
                        },
                        {
                            imageSrc: null,
                            title: "Sports Massage",
                            description: "Release muscle tension and scarring caused by repetitive movement and injuries by an accredited specialist. ",
                            link: "#",
                            linkText: "More Info"
                        }
                    ]}
                />
            </BgContainer>

            <Features
                subheading={""}
                heading="WELLBEING"
                description={<>
                    <i>"The mind is a powerful thing. Filter it with positive thoughts, your life will start to
                        change." </i>
                    <span tw="font-semibold">
                    - Buddha
                </span></>}
                cards={[
                    {
                        imageSrc: null,
                        title: "PERFORMANCE THERAPY",
                        description:
                            "Connecting the body and mind to optimise performance and reduce risk of injury with an evidence-based approach by experienced professionals and scientists.",
                        link: "#"

                    },
                    {
                        imageSrc: null,
                        title: "SOFT TISSUE THERAPY",
                        description: "Balancing movement and inflammatory response by hands-on manipulation to enhance healing",
                        link: "#"
                    },
                    {
                        imageSrc: null,
                        title: "PELVIC HEALTH",
                        description: "Balancing the innermost part of your core, rebuild a foundation for the road to recovery. ",
                        link: "#"
                    },
                ]}
                linkText="More Info"
                hideBubble
                bgImage={WellBeingImg}
            />
            <BgContainer>
                <Carousel
                    bgColor="white"
                    subheading=""
                    heading={<><span className="gradient-text">Biometrics</span> is what we do.</>}
                    testimonials={[
                        {
                            imageSrc: FootPressureImg,
                            heading: "FOOT PRESSURE",
                            description: "Enhanced pressure plate and 3D touch point analysis, building a footprint to success."
                        },
                        {
                            imageSrc: GaitAnalysisImg,
                            heading: "GAIT ANALYSIS",
                            description: "Motion capture analysis incorporating artificial intelligence with a human touch, to understand movement specifically to you."
                        },
                        {
                            imageSrc: BracingImg,
                            heading: "BRACING",
                            description: "Reputable expert guidance on sleeves, supports and braces, guided by motion data and clinical know how."
                        },
                    ]}
                    cards={[
                        {
                            imageSrc: null,
                            title: "Foot Therapy",
                            description:
                                "Enhanced pressure plate and 3D touch point analysis, building a footprint to success.",
                            link: "#",
                            linkText: "More Info"

                        },
                        {
                            imageSrc: null,
                            title: "Gait Therapy",
                            description: "Motion capture analysis incorporating artificial intelligence with a human touch, to understand movement specifically to you.",
                            link: "#",
                            linkText: "More Info"
                        },
                        {
                            imageSrc: null,
                            title: "Bracing",
                            description: "Reputable expert guidance on sleeves, supports and braces, guided by motion data and clinical know how.",
                            link: "#",
                            linkText: "More Info"
                        },
                    ]}
                />
            </BgContainer>
            <GridColFeatures
                subheading={
                    <Subheading className="gradient-text">Solutions</Subheading>
                }
                heading="Solutions for Pain Management, Performance, Wellbeing and Physical Health. "
                description=""
                grids={[
                    {
                        imageSrc: Service1,
                        title: "Merry Christmas",
                        authorName: "Paul Lee",
                        url: "#",
                    },
                    {
                        imageSrc: Service2,
                        title: "Merry Christmas",
                        authorName: "Paul Lee",
                        url: "#",
                    },
                    {
                        imageSrc: Service3,
                        title: "Getting ready for 2021",
                        authorName: "Paul Lee",
                        url: "#",
                    },
                ]}
                linkText="OUR SOLUTIONS"
            />

            <GetStarted/>
            <Footer/>
            {openModal && <NewsLetterModal onClose={() => setOpenModal(false)}/>}
        </AnimationRevealPage>
    );
};
