import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import {SectionHeading, Subheading as SubheadingBase,} from "components/misc/Headings.js";
import {SectionDescription} from "components/misc/Typography.js";
import {Container, ContentWithPaddingXl} from "components/misc/Layouts.js";
import {ReactComponent as ArrowRightIcon} from "images/arrow-right-icon.svg";
import {ReactComponent as SvgDecoratorBlob1} from "images/svg-decorator-blob-1.svg";
import {css} from "styled-components/macro";

const Heading = tw(SectionHeading)``;
const Subheading = tw(SubheadingBase)`text-center mb-3`;
const Description = tw(SectionDescription)`text-center mx-auto`;
const ThreeColumnContainer = styled.div`
  ${tw`mt-10 flex flex-col items-center lg:items-stretch lg:flex-row flex-wrap lg:justify-center max-w-screen-lg mx-auto`}
`;
const Column = styled.div`
  ${tw`lg:w-1/2 max-w-xs`}
`;

const Card = styled.a`
  ${tw`flex flex-col items-center text-center h-full mx-4 px-4 py-8 rounded transition-transform duration-300 hover:cursor-pointer transform hover:scale-105 `}
  .imageContainer {
    ${tw`text-center rounded-full p-4 bg-gray-100`}
    img {
      ${tw`w-12 h-12`}
    }
  }

  .title {
    ${tw`mt-4 font-bold text-xl leading-none`}
  }

  .description {
    ${tw`mt-4 text-sm font-medium text-secondary-300`}
  }

  .link {
    ${tw`mt-auto inline-flex items-center pt-5 text-base font-semibold text-primary-300 leading-none hocus:text-primary-900 transition duration-300`}
    .icon {
      ${tw`ml-2 w-4`}
    }
  }
`;

const BgContainer = styled.div((props) => [
    props.bgImage ? `background-image:  linear-gradient(
to right bottom,
 rgba(247, 164, 32, 0.5), 
      rgba(240, 143, 29, 0.5), 
      rgba(234, 103, 15, 0.5), 
      rgba(213, 90, 12, 0.5), 
      rgba(193, 78, 10, 0.5), 
      rgba(173, 66, 8, 0.5), 
      rgba(153, 54, 6, 0.5), 
      rgba(133, 43, 4, 0.5)
), 
    url("${props.bgImage}");
    background-attachment: fixed; background-position: center;` :
        ``,
    css`
    color: #131e46 !important;
    .description, p{
    color: white !important;
    }
    .link{
        color: #131e46 !important;
    }
    `
])

const DecoratorBlob = styled(SvgDecoratorBlob1)((props) => [
    tw`pointer-events-none absolute top-0 h-128 w-144 opacity-50  z-10`,
    props.bubblePosition === "left" ?
        tw`left-0 transform -translate-x-2/3` :
        tw`right-0 transform translate-x-2/3`,
]);

export default ({
                    cards = [],
                    linkText = "Learn More",
                    heading = "",
                    subheading = "",
                    link = "#",
                    description = "",
                    imageContainerCss = null,
                    imageCss = null,
                    leftContent = false,
                    bubblePosition = "left",
                    hideBubble = false,
                    bgImage = null
                }) => {
    return (
        <BgContainer bgImage={bgImage}>
            <Container>
                <ContentWithPaddingXl>
                    {subheading && <Subheading>{subheading}</Subheading>}
                    {heading && <Heading>{heading}</Heading>}
                    {description && <Description>{description}</Description>}
                    <ThreeColumnContainer>
                        {cards.map((card, i) => (
                            <Column key={i}>
                                <Card
                                    href={card.url}
                                    style={{
                                        alignItems: !leftContent ? "center" : "flex-start",
                                        textAlign: !leftContent ? "center" : "left",
                                    }}
                                >
                                    {card.imageSrc ? <span className="imageContainer" css={imageContainerCss}>
                  <img src={card.imageSrc} alt="" css={imageCss}/>
                </span> : null}
                                    <span className="title">{card.title}</span>
                                    <p className="description">{card.description}</p>
                                    {linkText && (
                                        <a href={card.link ?? link} className="link">
                                            <span>{linkText}</span>
                                            <ArrowRightIcon className="icon"/>
                                        </a>
                                    )}
                                </Card>
                            </Column>
                        ))}
                    </ThreeColumnContainer>
                </ContentWithPaddingXl>
                {!hideBubble && <DecoratorBlob bubblePosition={bubblePosition}/>}
            </Container>
        </BgContainer>
    );
};
