import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import Helen from "images/team/Helen.webp";
import Elaine from "images/team/Elaine.webp";
import Rob from "images/team/Rob.webp";
import {Container, ContentWithPaddingXl} from "components/misc/Layouts.js";
import {SectionHeading, Subheading as SubheadingBase,} from "components/misc/Headings";
import {PrimaryButton as PrimaryButtonBase} from "../misc/Buttons";

const HeadingContainer = tw.div``;
const Heading = tw(SectionHeading)``;
const Subheading = tw(SubheadingBase)`text-center mb-3`;
const Description = tw.p`mt-4 text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100 max-w-xl text-center`;

const Cards = tw.div`flex flex-wrap flex-row justify-center sm:max-w-2xl lg:max-w-5xl mx-auto`;
const Card = tw.div`mt-24 w-full sm:w-1/2 lg:w-1/3 flex flex-col min-h-full items-center`;
const CardImage = styled.img`
  ${tw`w-64 h-64 bg-contain bg-center rounded`}
`;
const CardContent = styled.div`
  ${tw`flex flex-col items-center mt-6`}
  .position {
    ${tw`uppercase font-bold tracking-widest text-xs text-primary-500`}
  }
  .name {
    ${tw`mt-1 text-xl font-medium text-gray-900`}
  }
  .description {
    ${tw`mt-2 text-base font-normal text-gray-700`}
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4; /* Number of lines to show */
    -webkit-box-orient: vertical;
  }
`;
const Button = styled(PrimaryButtonBase)`
  ${tw`rounded-full mt-10 uppercase tracking-wider py-4 text-sm hover:shadow-xl transform hocus:translate-x-px hocus:-translate-y-px focus:shadow-outline`}
`;

export default ({
                    heading = "Meet These Fine Folks",
                    subheading = "Our Team",
                    description = "",
                    cards = [
                        {
                            name: "Helen Morris",
                            title: "Senior Physiotherapist",
                            description: "Upper Limb Physio Expert",
                            image: Helen,
                            profileLink: "#",
                        },
                        {
                            name: "Rob Duffy",
                            title: "Senior Physiotherapist",
                            description: "Upper Limb Physio Expert",
                            image: Rob,
                            profileLink: "#",
                        },
                        {
                            name: "Elaine Rycroft",
                            title: "Clinical Hypnotherapist",
                            image: Elaine,
                            profileLink: "#",
                        }
                    ],
                }) => {
    return (
        <Container>
            <ContentWithPaddingXl>
                <HeadingContainer>
                    {subheading && <Subheading>{subheading}</Subheading>}
                    {heading && <Heading>{heading}</Heading>}
                    {description && <Description>{description}</Description>}
                </HeadingContainer>
                <Cards>
                    {cards.map((card, index) => (
                        <Card key={index}>
                            <CardImage src={card.image}/>
                            <CardContent>
                                <span className="position">{card.title}</span>
                                <span className="name">{card.name}</span>
                                <span className="description">{card.description}</span>
                                <Button onClick={() => window.open(card.link)}>
                                    Read More
                                </Button>
                            </CardContent>
                        </Card>
                    ))}
                </Cards>
            </ContentWithPaddingXl>
        </Container>
    );
};
