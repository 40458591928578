import "slick-carousel/slick/slick.css";
import React, {useState} from "react";
import Slider from "react-slick";
import tw from "twin.macro";
import styled from "styled-components";
import {SectionHeading, Subheading as SubheadingBase,} from "components/misc/Headings.js";
import {ReactComponent as ArrowLeftIcon} from "images/arrow-left-3-icon.svg";
import {ReactComponent as ArrowRightIcon} from "images/arrow-right-3-icon.svg";

const Container = tw.div`relative md:max-w-screen-xl mx-auto`;

const ContentWithVerticalPaddingXl = tw.div`sm:py-12 py-20 lg:py-24`;
const ContentWithHorizontalPaddingXl = tw.div`max-w-screen-xl mx-auto`;

const Column = tw.div`max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 xl:w-6/12 flex-shrink-0 relative`;
const TextColumn = styled(Column)((props) => [
    tw`md:w-7/12 xl:w-6/12 mt-16 md:mt-0`,
    props.textOnLeft
        ? tw`md:pr-12 lg:pr-16 md:order-first`
        : tw`md:pl-12 lg:pl-16 md:order-last`,
]);

const Image = styled.img((props) => [
    props.imageRounded && tw`rounded`,
    props.imageBorder && tw`border`,
    props.imageShadow && tw`shadow`,
]);

const Subheading = tw(SubheadingBase)`text-center`;
const Title = tw(
    SectionHeading,
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center leading-tight`;
const Heading = tw(
    SectionHeading,
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-4xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-6 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const TestimonialSlider = styled(Slider)`
  ${tw`w-full mt-16 text-center md:text-left`}
  .slick-track {
    ${tw`flex`}
  }
  .slick-slide {
    ${tw`h-auto flex justify-center mb-1`}
  }
`;

const Testimonial = tw.div`outline-none h-full flex! flex-col md:flex-row items-center`;

const CustomerInfoAndControlsContainer = tw.div`mt-auto flex justify-end items-center flex-col sm:flex-row`;

const Controls = styled.div`
  ${tw`flex mt-8 sm:mt-0`}
  .divider {
    ${tw`my-3 border-r`}
  }
`;
const ControlButton = styled.button`
  ${tw`mx-3 p-4 rounded-full transition duration-300 bg-gray-200 hover:bg-gray-300 text-primary-500 hover:text-primary-700 focus:outline-none focus:shadow-outline`}
  svg {
    ${tw`w-4 h-4 stroke-3`}
  }
`;

const ColumnContainer = styled.div`
  ${tw`mt-10 flex flex-col items-center lg:items-stretch lg:flex-row lg:justify-center max-w-screen-lg mx-auto`}
`;
const CardColumn = styled.div`
  ${tw`lg:w-1/2 max-w-xs my-4`}
`;

const Card = styled.a`
  ${tw`flex flex-col items-center text-center h-full mx-4 px-4 py-8 rounded transition-transform duration-300 hover:cursor-pointer transform hover:scale-105`}

  .title {
    ${tw`mt-4 font-bold text-xl leading-none`}
  }

  .description {
    ${tw`mt-4 text-sm font-medium text-secondary-300`}
  }

  .link {
    ${tw`mt-auto inline-flex items-center pt-5 text-base font-semibold text-primary-300 leading-none hocus:text-primary-900 transition duration-300`}
    .icon {
      ${tw`ml-2 w-4`}
    }
  }
`;


export default ({
                    subheading = "Testimonials",
                    textOnLeft = false,
                    testimonials = [
                        {
                            heading: "Amazing User Experience",
                            description:
                                "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.",
                            imageSrc: "path/to/image1.jpg",
                        },
                        {
                            heading: "Love the Developer Experience and Design Principles !",
                            description:
                                "Sinor Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                            imageSrc: "path/to/image2.jpg",
                        },
                    ],
                    cards = [],
                    bgColor = "#edf2f7",
                    heading,

                }) => {
    const [sliderRef, setSliderRef] = useState(null);

    return (
        <ContentWithVerticalPaddingXl>
            <Container>
                {subheading && <Subheading>{subheading}</Subheading>}
                {heading && <Title>{heading}</Title>}
                <TestimonialSlider autoplay autoplaySpeed={5000} arrows={false} ref={setSliderRef}>
                    {testimonials.map((testimonial, index) => (
                        <Testimonial key={index}>
                            <ImageColumn>
                                <Image
                                    src={testimonial.imageSrc}
                                    imageBorder={true}
                                    imageShadow={true}
                                    imageRounded={true}
                                />
                            </ImageColumn>
                            <TextColumn textOnLeft={textOnLeft}>
                                <Heading>{testimonial.heading}</Heading>
                                <Description>{testimonial.description}</Description>
                                <CustomerInfoAndControlsContainer>
                                    <Controls>
                                        <ControlButton style={{background: bgColor}} onClick={sliderRef?.slickPrev}>
                                            <ArrowLeftIcon/>
                                        </ControlButton>
                                        <div className="divider"/>
                                        <ControlButton style={{background: bgColor}} onClick={sliderRef?.slickNext}>
                                            <ArrowRightIcon/>
                                        </ControlButton>
                                    </Controls>
                                </CustomerInfoAndControlsContainer>
                            </TextColumn>
                        </Testimonial>
                    ))}
                </TestimonialSlider>
                <ContentWithHorizontalPaddingXl>
                    <ColumnContainer>
                        {cards.map((card, i) => (
                            <CardColumn key={i}>
                                <Card
                                    href={card.url}
                                    style={{
                                        alignItems: "center",
                                        textAlign: "center",
                                        background: bgColor
                                    }}
                                >
                                    <span className="title">{card.title}</span>
                                    <p className="description">{card.description}</p>
                                    {card.linkText && (
                                        <a href={card.link} className="link">
                                            <span>{card.linkText}</span>
                                            <ArrowRightIcon className="icon"/>
                                        </a>
                                    )}
                                </Card>
                            </CardColumn>
                        ))}
                    </ColumnContainer>
                </ContentWithHorizontalPaddingXl>
            </Container>
        </ContentWithVerticalPaddingXl>
    );
};
