import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import {Subheading as SubheadingBase} from "../misc/Headings";
import {SectionDescription} from "../misc/Typography";


const Subheading = tw(SubheadingBase)`text-center text-gray-200`;

const Container = styled.div((props) => [
  tw`relative -mx-8 bg-center bg-cover min-h-screen`,
`background-image:  linear-gradient(
to right bottom,
 rgba(247, 164, 32, 0.5), 
      rgba(240, 143, 29, 0.5), 
      rgba(234, 103, 15, 0.5), 
      rgba(213, 90, 12, 0.5), 
      rgba(193, 78, 10, 0.5), 
      rgba(173, 66, 8, 0.5), 
      rgba(153, 54, 6, 0.5), 
      rgba(133, 43, 4, 0.5)
), 
    url("${props.bgImage}");
    background-attachment: fixed;`,
])

const HeroContainer = tw.div`z-20 relative px-6 sm:px-8 mx-auto h-screen flex flex-col`;
const Content = tw.div`px-4 flex flex-1 flex-col justify-center items-center h-screen`;
const Description = tw(SectionDescription)`mx-auto text-center text-white`;

const Heading = styled.h1`
  ${tw`text-3xl text-center sm:text-4xl lg:text-5xl xl:text-6xl font-black text-white leading-snug sm:mt-0`}
  span {
    ${tw`inline-block mt-2`}
  }
`;

const PrimaryButton = tw.button`rounded-full px-8 py-3 mt-10 text-sm sm:text-base sm:mt-16 sm:px-8 sm:py-4 bg-gray-100 font-bold shadow transition duration-300 bg-white text-gray-100 hocus:scale-105 hocus:text-gray-200 focus:outline-none focus:shadow-outline`;


export default ({
                  heading = <>You, Only Better</>,
                  subheading,
                  description = "Integrating Science, Engineering, and Data for Transformative Health Solutions",
                  buttonText = "DISCOVER MORE",
                  onClick = () => {
                  },
                  bgImage
                }) => {

  return (
      <Container bgImage={bgImage }>
        <HeroContainer>
          <Content style={{ padding: "5rem 0px 3rem" }}>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            <Description>{description}</Description>
            {buttonText ? (
                <PrimaryButton onClick={onClick}>
                  <span className="gradient-text">{buttonText}</span>
                </PrimaryButton>
            ) : null}
          </Content>
        </HeroContainer>
      </Container>
  );
};
